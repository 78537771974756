import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import routerBook from '../../../routes/routerBook';
import logo from '../../../assets/images/header/logo.svg';
import './styles.scss';
import TertiaryHeader from './tertiaryHeader/tertiaryHeader';
import SecondaryHeader from './secondaryHeader/secondaryHeader';
import { useSelector } from 'react-redux';
import { Logo } from '../logo/Logo';

const HeaderNavigationPanel = ({ t }) => {
    const { data } = useSelector(state => state.authState);
    const { pathname } = useLocation();
    const { id } = useParams();

    const {
        login,
        registration,
        forgotPassword,
        checkCode,
        checkCodeLink,
        resetPassword,
        resetPasswordLink,
        paymentPage,
        question,
        privacyPolicy,
        termsOfService,
        updatePlan,
        main,
    } = routerBook;

    const disabledHeader = [
        termsOfService,
        privacyPolicy,
        login,
        registration,
        forgotPassword,
        checkCode,
        resetPassword,
        checkCodeLink,
        resetPasswordLink,
    ].map(el => (el ? el.replace(':id', id) : el));

    const bigOrangeHeader = [question, updatePlan];

    const bigWhiteHeader = [paymentPage];

    if (bigOrangeHeader.includes(pathname)) return <header className={`header-big-orange`} />;

    if (bigWhiteHeader.includes(pathname)) return <header className={`header-big-white`} />;

    if (disabledHeader.includes(pathname)) return null;

    if (data && (data.paymentInformation?.length || data?.apple) && ![paymentPage, question].includes(pathname))
        return <TertiaryHeader />;

    if ([paymentPage, question].includes(pathname) && data) {
        return (
            <header className="header-primary">
                <Link to={routerBook.main}>
                    <Logo className="header-primary__logo" />
                </Link>
            </header>
        );
    }

    return null;
};
export default withTranslation()(HeaderNavigationPanel);

import { object, string } from 'yup';
import valid from 'card-validator'; //import statement

export const creditCardSchema = object().shape({
    cardNumber: string()
        .test(
            'test-number',
            'Credit Card number is invalid',
            value => {
                return valid.number(value).isValid || (value === '0000 0000 0000 0000');
            },
        )
        .required('This field is required'),
    date: string().required('This field is required').min(5, 'date is not valid')
        .max(5, 'date is not valid'),
    cvv: string().test(
        'test-number',
        'CVV is invalid',
        value => {
            return valid.cvv(value, 4).isValid || valid.cvv(value, 4).isPotentiallyValid || (value === 'CVV');
        },
    )
        .required('This field is required').min(3, 'CVV is invalid')
        .max(4, 'CVV is invalid'),
});

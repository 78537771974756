import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import imgFreeChallenge from '../../../../assets/images/profile/back.png';
import { Button } from '../../../ui-core';
import './styles.scss';
import { useDispatch } from 'react-redux';
import CustomSelect from '../../../customSelect/CustomSelect';
export const FreeChallengeModal = ({ onClose, data, t }) => {
    const dispatch = useDispatch();

    const onSend = () => {
        onClose && onClose();
        try {
            data?.callback();
        } catch (err) {}
    };

    const closeHandler = () => {
        onClose && onClose();
    };
    const onCancleButton = () => {
        onClose && onClose();
        try {
            data?.calcelCallback();
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <div className="free-default-modal">
                <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} />
                <div className="modal-body">
                    <div className="left-content">
                        <p className="modal-title">FREE FOREVER</p>
                        <p className="modal-title-2">NEED MOTIVATION TO WORKOUT?</p>
                        <p className="modal-content-1">
                            Try our Stay Fit Free Forever Challenge. Workout 3x per week for the month and that month is
                            free.
                        </p>
                        <p className="modal-content-2">
                            There is no end date for this challenge. When you workout it's free, it's that simple. If
                            you still wish to cancel click <a onClick={onCancleButton}>here</a>. But what better way is
                            there to encourage you to workout and get results.
                        </p>
                        <p className="modal-content-3">Cancel Anytime</p>
                        <Button className="free-popup-select-submit-not-now" onClick={onSend}>
                            JOIN THE CHALLENGE
                        </Button>
                        <p className="modal-content-4">First day of challenge will start 2 days after joining.</p>
                    </div>
                    <img src={imgFreeChallenge} className="right-content" width={350} />
                </div>
            </div>
        </>
    );
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

import App from './components/App';

if (!localStorage.getItem('update-plan-ask-flag')) {
	localStorage.setItem('update-plan-ask-flag', 'true')
}

if (!localStorage.getItem('update-plan-ask-action')) {
	localStorage.setItem('update-plan-ask-action', '')
}

if (!localStorage.getItem('remember-user')) {
	localStorage.setItem('remember-user', 'false')
}

if (!!process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/beta\.theformulaic\.com/],
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [
          window.location.origin,
          'api.theformulaic.com',
          'vimeo.com'
        ]
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);

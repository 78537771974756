import routerBook from '../../../routes/routerBook';

import { ReactComponent as Challenges } from '../../../assets/images/header/challenges.svg';
import { ReactComponent as Workouts } from '../../../assets/images/header/workouts.svg';
import { ReactComponent as MyPlan } from '../../../assets/images/header/plan.svg';
import { ReactComponent as Saved } from '../../../assets/images/header/saved.svg';
import { ReactComponent as Update } from '../../../assets/images/header/update.svg';

import ChallengesMobile from '../../../assets/images/header/challengesMobile.svg';
import WorkoutsMobile from '../../../assets/images/header/workoutsMobile.svg';
import MyPlanMobile from '../../../assets/images/header/planMobile.svg';
import SavedMobile from '../../../assets/images/header/savedMobile.svg';
import UpdateMobile from '../../../assets/images/header/updateMobile.svg';

export const tertiaryConfig = [
    {
        title: 'header_tertiary_my_plan',
        icon: MyPlan,
        iconMobile: MyPlanMobile,
        redirect: routerBook.dashboard,
    },
    {
        title: 'header_tertiary_workouts',
        icon: Workouts,
        iconMobile: WorkoutsMobile,
        redirect: routerBook.workouts,
    },
    {
        title: 'header_tertiary_challenges',
        icon: Challenges,
        iconMobile: ChallengesMobile,

        redirect: routerBook.challenges,
    },
    {
        title: 'header_tertiary_saved',
        icon: Saved,
        iconMobile: SavedMobile,
        redirect: routerBook.saved,
    },
    {
        title: 'header_tertiary_update_plan',
        icon: Update,
        iconMobile: UpdateMobile,
        redirect: routerBook.updatePlan,
    },
];
